<template>
  <span>
    <div>
      <comercial-v2-visao-detalhada-municipio
        v-if="tipoVisao === 'municipio'"
        ref="visaoDetalhadaMunicipio"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :temas_selecionados="temas_selecionados"
        :mostrarApenasErros="mostrarApenasErros"
        :chavesIndicadores="chavesIndicadores"
        :indicadoresLiberados="indicadoresLiberados"
        :codigoMunicipio="codigoMunicipio"
        @updateLoadingDados="$emit('updateLoadingDados', $event)"
        @updateDownloadingArquivoValidacoes="
          $emit('updateDownloadingArquivoValidacoes', $event)
        "
        @updateDataTable="getDados()"
      />
      <comercial-v2-visao-detalhada-consolidada
        v-else-if="tipoVisao === 'consolidada'"
        ref="visaoDetalhadaConsolidada"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :temas_selecionados="temas_selecionados"
        :mostrarApenasErros="mostrarApenasErros"
        :chavesIndicadores="chavesIndicadoresVisaoConsolidada"
        :indicadoresLiberados="indicadoresLiberados"
        :authorizedFields="authorizedFields"
        :authorizedCompanies="authorizedCompanies"
        @updateLoadingDados="$emit('updateLoadingDados', $event)"
        @updateDownloadingArquivoValidacoes="
          $emit('updateDownloadingArquivoValidacoes', $event)
        "
        @updateDataTable="getDados()"
      />
    </div>
  </span>
</template>

<script>
  export default {
    name: "ComercialV2VisaoDetalhada",
    components: {
      ComercialV2VisaoDetalhadaMunicipio: () =>
        import(
          "@/components/validacoes/ComercialV2VisaoDetalhadaMunicipio.vue"
        ),
      ComercialV2VisaoDetalhadaConsolidada: () =>
        import(
          "@/components/validacoes/ComercialV2VisaoDetalhadaConsolidada.vue"
        ),
    },
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      tipoVisao: {
        type: String,
        required: true,
      },
      indicadoresFieldsCategories: {
        type: Array,
        required: true,
      },
      authorizedFieldsCategories: {
        type: Array,
        required: false,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
      codigoMunicipio: {
        type: String,
        default: "",
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
    },
    data: () => ({
      chavesIndicadores: [
        { field: "com_001", brief_description: "CNPJ Empresa" },
        { field: "com_002", brief_description: "Código do Município" },
        { field: "com_003", brief_description: "Mês" },
        { field: "com_004", brief_description: "Ano" },
      ],
    }),
    computed: {
      chavesIndicadoresVisaoConsolidada() {
        // Visão consolidada não inclui cod. municipio (COM_002)
        return this.chavesIndicadores.filter(
          (chave) => chave.field.toLowerCase() !== "com_002"
        );
      },
      indicadores() {
        return this.indicadoresFieldsCategories
          .map((category) => category.fields)
          .flat();
      },
      indicadoresLiberados() {
        let indicadoresLiberados = [];

        this.indicadoresFieldsCategories.forEach((category) => {
          if (this.temas_selecionados.includes(category.slug)) {
            indicadoresLiberados = indicadoresLiberados.concat(category.fields);
          }
        });

        return indicadoresLiberados.toSorted((a, b) =>
          a.field.localeCompare(b.field)
        );
      },
      authorizedCategories() {
        return this.authorizedFieldsCategories.map(
          (category) => category.category
        );
      },
      authorizedFields() {
        let fields = [];

        this.authorizedFieldsCategories.forEach((category) => {
          fields = fields.concat(category.fields.map((field) => field.field));
        });

        return fields;
      },
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        temas_selecionados = this.temas_selecionados
      ) {
        switch (this.tipoVisao) {
          case "municipio":
            this.$refs.visaoDetalhadaMunicipio.getDados(
              competencia_de,
              competencia_ate,
              temas_selecionados
            );
            break;
          case "consolidada":
            this.$refs.visaoDetalhadaConsolidada.getDados(
              competencia_de,
              competencia_ate,
              temas_selecionados
            );
            break;
        }
      },
      downloadArquivoValidacoes() {
        switch (this.tipoVisao) {
          case "municipio":
            this.$refs.visaoDetalhadaMunicipio.downloadArquivoValidacoes();
            break;
          case "consolidada":
            this.$refs.visaoDetalhadaConsolidada.downloadArquivoValidacoes();
            break;
        }
      },
    },
  };
</script>

<style></style>
